<template>
  <div class="  t15">
    <div class="form-section-static first-section xs-fill-white">
      <div class="contact_content bg-opacity">
        <div class="bg-opacity">
          <p>{{$t('contact.hotline_1')}} <span  class="bold" style="color: #4cae4c">1300-88-2525</span> {{$t('contact.hotline_2')}}</p>
          <p>{{$t('contact.description')}}</p>
          <p>
          <p>{{$t('contact.enter_message')}}<span class="cl-red">{{ $t('required') }}</span></p>
          <p>
            <textarea v-bind:class="{ 'has-errors': errors.note , 'has-success': errors.note === false}" v-model="note" :placeholder="$t('contact.placehover_text')"  style="width: 100%;border-radius: 4px;border: 2px solid hsla(207, 8%, 51%, .5)" rows="3">

            </textarea>
          </p>
          <button style="text-decoration: none;" class="btn btn-success primary-cta"
              @click = "sendFeedback">{{$t('contact.send_your_feedback')}}</button>
        </div>
      </div>
    </div>
  </div>

</template>
<style scoped>
  .has-errors {
    border: 1px solid #da291c !important;
  }
</style>
<script>
export default {
  components: {},
  data () {
    return {
      note: '',
      errors: {
        note: false
      }
    }
  },
  mounted () {
    let page = {
      'id': 'contactus',
      'name': 'feed-form',
      'category': 'contact',
      'target': window.location.href
    }
    this.eventTrackingContactus(page)
    let formInfo = {
      id: 'contactus-form',
      name: 'contactus-form',
      category: 'contact',
      url: window.location.href
    }
    this.eventTrackingFormLoad(formInfo)

    this.$root.$emit('onChangeMetaTitle', this.$t(this.$route.meta.key_title))
  },
  methods: {
    sendFeedback () {
      let vm = this
      let params = {}
      let info = this.$auth.user()
      let flag = false

      if (!this.note) {
        this.errors.note = this.$t('required_display')
        flag = true
      } else {
        this.errors.note = false
      }
      if (flag) {
        return false
      }
      params.email = info.email ? info.email : ''
      params.name = this.getStringIgnoreHTTMLTag(info.first_name ? info.first_name : '' + ' ' + info.last_name ? info.last_name : '')
      params.phone = info.phone ? info.phone : ''
      params.note = this.getStringIgnoreHTTMLTag(this.note)
      params.send_email = 0
      this.eventTrackingFormSubmit({id: 'contactus-form'})
      this.axios.apiCustomer.sendToFeedback(params, function () {
        this.eventTrackingFormSuccess({id: 'contactus-form'})
        vm.$router.push('/')
        vm.$notify({
          type: 'success',
          title: vm.$t('send_successfull')
        })
      }, (error) => {
        error = error.error
        let formInfo = {
          id: 'register-form',
          'error': {
            'code': error.code,
            'type': 'validation',
            'error': error.message
          }
        }
        vm.eventTrackingFormError(formInfo)
      })
    }
  },
  watch: {
    note: function (newValue, oldValue) {
      if (!this.note) {
        this.errors.note = this.$t('required_display')
      } else {
        this.errors.note = false
      }
    }
  }
}
</script>
